<template>
  <status
    :title="$t('common.anErrorHasOcurred')"
    :body="errorText"
    type="error"
  >
    <template #action>
      <a v-if="email" @click="resetPassword()">{{ $t('setPassword.resendLink') }}</a>
    </template>
  </status>
</template>

<script>

export default {
  name: 'SetPasswordError',
  components: {
    Status: () => import('@/components/Status'),
  },
  props: {
    errorText: { type: String, required: true },
    email: { type: String, required: true },
  },
  methods: {
    resetPassword() {
      if (!this.email) return
      this.runAsync(() => this.$store.dispatch('user/resetPassword', this.email))
    },
  },
}
</script>

<style lang="scss" scoped>
.card {
  max-width: 800px;
  height: auto;
  box-shadow: -10px 10px 20px 0 rgba(30, 30, 30, 0.05);
}

.form {
  max-width: 320px;
}
</style>
